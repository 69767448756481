<template>
  <div id="console">
    <div class="auth">
      <div class="auth-form">
        <div id="title">Change Password</div>
        <center class="red" v-if="errors">{{ errors }}</center>
        <el-form v-if="valid">
          <label>Hi {{ user.name }}, enter new password to change:</label>
          <el-form-item label="">
            <el-tooltip
              class="item"
              effect="dark"
              content="Min 8 characters, at least one uppercase letter, one lowercase letter and one number"
              placement="top"
            >
              <el-input
                type="password"
                placeholder="New Password"
                v-model="password"
              >
              </el-input>
            </el-tooltip>
          </el-form-item>
          <div class="flex-space">
            <el-button type="primary" @click="change">CHANGE</el-button>
            <el-button type="text" @click="$router.replace({ name: 'console' })"
              >Back to Login</el-button
            >
          </div>
        </el-form>
        <p v-else>Your password reset link appears to be invalid.</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Password",
  data() {
    return {
      user: {},
      password: "",
      errors: null,
      valid: false,
    };
  },
  async mounted() {
    await this.verifyToken();
    this.$actions.setLayout("simple-layout");
  },
  methods: {
    async verifyToken() {
      try {
        const token = this.$route.query.token;
        const response = await this.$http.get(`/api/v1/passwords/${token}`);
        if (response.status == 200) {
          this.user = response.data;
          this.valid = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async change() {
      try {
        this.errors = null;
        const token = this.$route.query.token;
        const response = await this.$http.put(`/api/v1/passwords/${token}`, {
          password: this.password,
        });
        if (response.status == 200) {
          this.$router.replace({ name: "console" });
          this.$message.success(response.data.message);
        } else {
          this.errors = response.data.errors;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#console {
  height: 100vh;
  .auth {
    height: 100vh;
    background: #1f2828;
    padding-top: 100px;
    .auth-form {
      padding: 30px;
      width: 400px;
      margin: auto;
      background: #fff;
      border-radius: 10px;
    }
  }
}

#title {
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 0.8rem;
  margin-top: -15px;
}
</style>
